import "core-js/modules/es.array.concat.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useGuruUtils from "~/functions/useInvestorGuruUtils";
import algoliaSearch from "~/mixins/algoliaSearch";
export default defineComponent({
  mixins: [algoliaSearch("tikr-guru-sept")],
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      mainTier = _useBaseUtils.mainTier,
      freeTier = _useBaseUtils.freeTier,
      I18nFn = _useBaseUtils.I18nFn;
    var _useGuruUtils = useGuruUtils(),
      permIdObj = _useGuruUtils.permIdObj;
    return {
      refCode: refCode,
      mainTier: mainTier,
      permIdObj: permIdObj,
      freeTier: freeTier,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      history: function history() {
        return {};
      }
    };
  },
  methods: {
    selectGuru: function selectGuru(e) {
      this.$router.push("/investor?id=".concat(e.objectID, "&ref=").concat(this.refCode));
    }
  }
});